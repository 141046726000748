import React from 'react';
import { Col, Row, Select, Space, Tooltip, theme } from 'antd';
import { SwapOutlined, FileDoneOutlined, FileProtectOutlined, FileOutlined, FilePdfOutlined, AuditOutlined,DownCircleOutlined,UpCircleOutlined, FileExcelOutlined} from '@ant-design/icons';
import { URL_PLANTATIONS, URL_REPORT } from '../constants/urls';
import TSTable from '../components/common/TSTable';
import { PATH } from '../routes/CustomRoutes';
import ChangeStateModal from '../components/officer/ChangeStateModal';
import RecheckPlantationModal from '../components/officer/CheckPlantationModal';
import ColorButton from '../components/common/ColorButton';
import { DATE_FORMAT, HUMAN_DATE_FORMAT, PLANTATION_STATUS, PLANTATION_TRACEABILITY, HUMAN_DATETIME_FORMAT, DATETIME_FORMAT } from '../constants/string';
import moment from 'moment';
import ReportButton from '../components/common/ReportButton';
import ConfirmPlantitonModal from '../components/collectingcenter/ConfirmPlantitonModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function PlantationsListView() {
  const [openChangeStatModal, setOpenChangeStateModal] = React.useState(false)
  const [openCheckModal, setOpenCheckModal] = React.useState(false)
  const [filterStatus, setFilterStatus] = React.useState("is_draft=false")
  const [target, setTarget] = React.useState(null);
  const tableRef = React.useRef();
  const [loadings, setLoadings] = React.useState([]);
  const [openConfirmPlantion, setOpenConfirmPlantiton] = React.useState(false)
  const { t } = useTranslation();

  const {
		token: { colorWarning, colorInfo, colorSuccess ,colorPrimary},
	} = theme.useToken();

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const FARMER_STATUS = {
    draft: t("farmer_status.draft"), 
    checking: t("farmer_status.checking"), 
    waiting: t("farmer_status.waiting"), 
    approved: t("farmer_status.approved"), 
    rejected: t("farmer_status.rejected"), 
  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("plantation"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        landscape: true,
        fetchPageSize: 200,
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("plantation"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `แปลงสวนยาง - ${dayjs().format(DATETIME_FORMAT)}.xlsx`,
        fetchPageSize: 200,
      })
  }}

  const buttonloading = (original,index) =>{
    try{
      setLoadings((state) => {
        const newLoadings = [...state];
        newLoadings[index] = true;
        return newLoadings;
      });
      if(original){
        window.open(original.recent_survey_documents[0])
      }
    }
    catch{
    }
    finally{
        setLoadings((state) => {
          const newLoadings = [...state];
          newLoadings[index] = false;
          return newLoadings;
      });
    }
    }
  const columns = [
    {
      title: t("plantations.approval_date"),
      dataIndex:"created",
      render: (value) => moment(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT),
      sorter: true,
    },
    {
      title: t("plantations.lastest_update"),
      dataIndex:"updated",
      render: (value) => moment(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT),
      sorter: true,
    },
    {
      title: t("plantations.member_name"),
      dataIndex: "farmer_name",
      sorter: true,
      searcher: true,
    },
    {
      title: t("plantations.plantation_code"),
      width: 120,
      dataIndex: "code",
      searcher: true,
    },
    {
      title: t("plantations.deed_type"),
      width: 160,
      dataIndex: 'title_deed_type_name',
    },
    {
      title: t("plantations.deed_code"),
      width: 160,
      dataIndex: 'title_deed_no',
    },
    {
      title: t("plantations.status"),
      width: 150,
      dataIndex: 'status',
      render: (value, original) => {
        return FARMER_STATUS[value]
      }
    },
    {
      title: t("plantations.under_collecting_center"),
      dataIndex:'farmer_created_by',
      searcher: true,
      render:(value , origin) =>{
        // return (origin.farmer_created_by_type && origin.farmer_created_by_type === USER_TYPE.OFFICER || origin.farmer_created_by_type === USER_TYPE.COLLECTING_CENTER ? `${origin.farmer_created_by}` : '-')
        return value;
      }
    },
    {
      title: t("plantations.document"),
      width: 140,
      skipReport: true,
      dataIndex: '',
      render: (value, original,index) => {
        return (
          <div style={{ textAlign : 'center'}}>
          {original.recent_survey_documents[0] && 
            <Tooltip title={t("plantations.download")}>
              <ColorButton 
                icon={<FileOutlined />}
                loading={loadings[index]}
                onClick={() => {              
                  // window.open(original.recent_survey_documents[0])
                  buttonloading(original,index)
                  
                }}
              />
            </Tooltip>
          }
          </div>
        )
      }
      
    },
    {
      title: 'Action',
      width: 140,
      dataIndex: 'id',
      skipReport: true,
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space>
            {original.status === PLANTATION_STATUS.WAITING && 
              <Tooltip title="ปรับปรุงสถานะ">
                <ColorButton
                  type='primary'
                  override={colorWarning}
                  icon={<SwapOutlined />}
                  onClick={() => {
                    setOpenChangeStateModal(true) 
                    setTarget(original)
                  }}/>
              </Tooltip>}
              {original.status === 'checking' && <Tooltip title="ปรับปรุงสถานะ">
              <ColorButton
                type='primary'
                override={colorWarning}
                icon={<AuditOutlined />}
                onClick={() => {
                  setOpenCheckModal(true)
                  setTarget(original)
                }} />
            </Tooltip>}
            {original.status === PLANTATION_STATUS.APPROVED && 
              <Tooltip title={t("plantations.detail_document")}>
                <ColorButton
                  type='primary'
                  override={colorSuccess}
                  icon={<FileProtectOutlined />}
                  onClick={() => {
                    setOpenCheckModal(true)
                    setTarget(original)
                  }}/>
              </Tooltip>}
            <Tooltip title={t("plantations.detail")}>
              <ColorButton 
                type='primary'
                override={colorInfo}
                icon={<FileDoneOutlined />}
                onClick={() => window.open(`${PATH.PLANTATION_DETAIL}/${original.farmer}/${value}`)} />
            </Tooltip>
            <Tooltip title={t("plantations.download")}>
                <ReportButton
                icon={<FilePdfOutlined />}
                style={{marginLeft: 4, marginRight: 4 }} 
                url={`${URL_REPORT.APP05}?plantation=${original.id}`}/>
            </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ];

  React.useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch()
    }
  }, [filterStatus]);

  return (
    <div>
      <Space direction='vertical' style={{width :'100%'}}>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col md={6} sm={24} xs={24}>
            <Select
              style={{width: "100%"}}
              value={filterStatus}
              onChange={value => setFilterStatus(value)}
              options={[
                { value: "is_draft=false", label: t("status_map.all") },
                { value: "status=draft", label: t("status_map.draft")},
                { value: "status=checking", label: t("status_map.checking") },
                { value: "status=waiting", label: t("status_map.waiting") },
                { value: "status=approved", label: t("status_map.approved") },
                { value: "status=rejected", label: t("status_map.rejected") }
              ]}/>
          </Col>
          <Col md={18} sm={24} xs={24}>
            <ColorButton
              icon={<FileExcelOutlined />}
              style={{float: "right",  marginLeft : 8 }}
              onClick={() => exportTable("excel")}>
              Excel
            </ColorButton>
            <ColorButton
              icon={<FilePdfOutlined />}
              style={{float: "right",}}
              onClick={() => exportTable("pdf")}>
              PDF
            </ColorButton>
          </Col>
        </Row>
      </Space>
      <TSTable
        ref={tableRef}
        autoFetch={false}
        columns={columns}
        url={`${URL_PLANTATIONS.PLANTATIONS}?${filterStatus}`}
        rowKey={"id"}
        rowClassName={(record) => {
          if (record.is_critical && record.status === PLANTATION_STATUS.CHECKING) {
            return 'table-row-red'
          }
        }}
        params={{ filter_user_type: true }}
        size={"small"}
        pageSize={10}/>

      <ChangeStateModal
        open={openChangeStatModal}
        onUpdate={() => {
          setOpenChangeStateModal(false)
          setTarget(null)
          refreshData();
        }}
        onClose={() => {
          setOpenChangeStateModal(false)
          setTarget(null)
        }}
        data={target}
      />

      <RecheckPlantationModal
        open={openCheckModal}
        onUpdate={() => {
          setOpenCheckModal(false)
          setTarget(null)
          refreshData();
        }}
        onClose={() => {
          setOpenCheckModal(false)
          setTarget(null)
        }}
        data={target}
      />
      <ConfirmPlantitonModal
        open={openConfirmPlantion}
        onClose={() => {
          setOpenConfirmPlantiton(false)}}
        onUpdate={() => {
          setOpenConfirmPlantiton(false)
            setTarget(null)
            refreshData();
          }}
          data = {target}
      />
    </div>
  )
}