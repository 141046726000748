import React from 'react';
import { Alert, theme, Tooltip, Popconfirm, Space, Card, message, Input, Row, Col, Skeleton, Empty } from 'antd';
import TSTable from '../components/common/TSTable';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, FileDoneOutlined, FilePdfOutlined, SearchOutlined, QrcodeOutlined, FileExcelOutlined } from '@ant-design/icons';
import { CYAN_COLOR, SECONDARY_COLOR } from '../constants/color';
import {useNavigate,} from 'react-router-dom';
import { URL_MEMBER, URL_REPORT } from '../constants/urls';
import { PATH } from '../routes/CustomRoutes';
import { DELETE, GET } from '../frameworks/HttpClient';
import { DATETIME_FORMAT, FARMER_STATUS_OPTIONS, FARMER_STATUS_OPTIONS_EN, HUMAN_DATETIME_FORMAT, IS_FSC_OPTIONS, LANGUAGE } from '../constants/string';
import ColorButton from '../components/common/ColorButton';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import { List } from 'antd-mobile'
import _ from 'lodash';
import { Button ,Modal } from 'antd-mobile'
import Meta from 'antd/es/card/Meta';
import ReportButton from '../components/common/ReportButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import QRCodeModal from '../components/farmer/QRCodeModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';


export default function MemberListView() {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const tableRef = React.useRef(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md')
  const [targetQR, setTargetQR] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [dataid ,setDataid] =React.useState([]);
  const [search, setSearch] = React.useState('');
  const [dataFarmer, setDataFarmer] = React.useState([]); 
  const [dataLazyLoad, setDataLazyLoad] = React.useState(`${URL_MEMBER.FARMER}?page_size=10&search=${search}&filter_user_type=true`);
  const { t, i18n } = useTranslation();

  const fetchDataFarmerSearch = async () => {
    try {
      const response = await GET(`${URL_MEMBER.FARMER}?page_size=10&search=${search}&filter_user_type=true`);
      setDataFarmer(response.data.results);
      setDataLazyLoad(response.data.links.next);
    } catch (error) {
      message.error(error.errorMessages);
    } 
  };
  const fatchDataFarmer = async() =>{
      if(dataLazyLoad){
      const response = await GET(dataLazyLoad)
      setDataFarmer([...dataFarmer, ...response.data.results])
      setDataLazyLoad(response.data.links.next)
    }
  };
const fetchMoreData= () => {
  setTimeout(fatchDataFarmer, 1000);
};
const handleOpenModal = (item) => {
  setDataid(item)
  setVisible(true);
};

const handleCloseModal = () => {
  setVisible(false);
  setDeleteId(null)
};

const exportTable = async(type) => {
  if(tableRef.current){
  if(type == "pdf"){
    await tableRef.current.exportPDF({
      title: t("member_list"),
      subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
      // landscape: true
    })
  } else {
    await tableRef.current.exportXlsx({
      title: t("member_list"),
      subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
      filename: `${t("member_list")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
    })
  }
}}

  // Handle delete member
  const handleDelete = async (id) => {
    if (!id) {
      setErrorMessages("ไม่พบรหัสสมาชิก!");
      return;
    }

    setErrorMessages(null);
    try {
      await DELETE(`${URL_MEMBER.FARMER}${id}/`)
      tableRef.current.fetch();
      setDeleteId(null)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(()=>{
    fatchDataFarmer();
  },[])
  const {
    token: { colorError, colorWarning, colorInfo ,colorPrimary}
  } = theme.useToken();

  const columns = [
    {
      title: t("profiles.full_name"),
      dataIndex: i18n.language === 'th' ? "full_name" : "full_name_en",
      sorter: true,
      searcher: true,
    },
    {
      title: t("profiles.phone"),
      width: 140,
      dataIndex: "phone",
      searcher: true,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      render: (value) => t(`farmer_status.${value}`),
      searcher: true,
      searcherOptions: i18n.language === LANGUAGE.TH ? FARMER_STATUS_OPTIONS : FARMER_STATUS_OPTIONS_EN,
    },
    {
      title: t('document'),
      dataIndex: 'id',
      skipReport: true,
      render: (value, original) => {
        return (
          <div style={{ textAlign : 'center' }}>
            {original.pdpa_signature && (
              <Tooltip title={t("documents.pdpa_th")}>
                <ReportButton
                  icon={<FilePdfOutlined />}
                  url={`${URL_REPORT.PDPA_CONSENT}?farmer=${value}&lang=th`}
                >
                  PDPA_TH
                </ReportButton>
              </Tooltip>
            )}

            {original.pdpa_signature && (
              <Tooltip title={t("documents.pdpa_en")}>
                <ReportButton
                  icon={<FilePdfOutlined />}  
                  url={`${URL_REPORT.PDPA_CONSENT}?farmer=${value}&lang=en`}
                >PDPA_EN

                </ReportButton>
              </Tooltip>
            )}

            {original.is_fsc && original.consent_signature && (
              <Tooltip title={t("documents.consent")}>
                <ReportButton
                  icon={<FilePdfOutlined />}
                  url={`${URL_REPORT.FSC_CONSENT}?farmer=${value}&export=pdf`}
                >
                FSC
                </ReportButton>
              </Tooltip>
            )}
          </div>
        )
      }
    },
    {
      title: t('action'),
      width: 180,
      dataIndex: 'id',
      skipReport: true,
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space>
              <Tooltip title={t("actions.edit")}>
                <ColorButton
                  type={"primary"}
                  override={colorWarning}
                  icon={<EditOutlined />}
                  onClick={() => window.open(`${PATH.MEMBER_REGISTER}/${value}`)} />
              </Tooltip>
              <Tooltip title={t("plantation")}>
                <ColorButton 
                  type={"primary"} 
                  override={colorInfo}
                  icon={<FileDoneOutlined />}
                  onClick={() => window.open(`${PATH.FARMER_PLANTATIONS}/${value}`)} />
              </Tooltip>
              <Tooltip title={t("actions.line_qr_code")}>
                <ColorButton 
                  type={"primary"} 
                  override={CYAN_COLOR}
                  icon={<QrcodeOutlined />}
                  onClick={() => setTargetQR(original)}/>
              </Tooltip>
              <Tooltip title= {t('actions.delete_data')}>
              <Popconfirm
                title={t("farmers.delete_title")}
                description={t("farmers.delete_confirm")}
                onConfirm={() => handleDelete(value)}
                okText={t("delete")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}>
                <ColorButton 
                  type={"primary"}
                  override={colorError} 
                  icon={<DeleteOutlined />} />
              </Popconfirm>
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: '1rem', textAlign: "left"}}
        />
      }
      {isOnMobile ? (
        <Row>
          <Space direction='vertical' style={{width :'100%'}}>
            <Col md={24} sm={24} xs={24}>
              <Button 
                block color='warning'
                size='large'
                icon={<PlusCircleOutlined />} 
                onClick={() => navigate(PATH.MEMBER_REGISTER)}>
                  {t("members.add_member")}
              </Button>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Space.Compact style={{ width: '100%' }} size='middle'>
                <Input placeholder={`${'search'}...`} onPressEnter={() => fetchDataFarmerSearch()} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
                <Button icon={<SearchOutlined/>} onClick={() => fetchDataFarmerSearch()}>{t("search")}</Button>
              </Space.Compact>
            </Col>
          </Space>
        </Row>
      ):(
        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <ColorButton
                icon={<FilePdfOutlined />}
                style={{float: "right"}}
                onClick={() => exportTable("pdf")}>
                PDF
              </ColorButton>
            </Col>
            <Col>
              <ColorButton
                icon={<FileExcelOutlined />}
                style={{float: "right"}}
                onClick={() => exportTable("excel")}>
                Excel
              </ColorButton>
            </Col>
            <Col>
              <ColorButton 
                type={"primary"}
                override={SECONDARY_COLOR}
                icon={<PlusCircleOutlined />} 
                style={{float: "right", marginBottom: '1rem'}}
                onClick={() => navigate(PATH.MEMBER_REGISTER)}>
                  {t("members.add_member")}
              </ColorButton>
            </Col>
          </Row>
        </Space>
      )}
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: '1rem', textAlign: "left"}}
        />
      }
      {isOnMobile ? (
        <div>
          {!dataFarmer.length && <Empty/>} 
          <List>
          <InfiniteScroll
            dataLength={dataFarmer.length}
            next={fetchMoreData}
            hasMore={dataLazyLoad}
            hasChildren={dataLazyLoad}
            loader={
              <Skeleton
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }>
          {dataFarmer && dataFarmer.map(item => (
            <List.Item
              key={item.id}
              >
            <Card
              title={<p style={{fontSize: '16px'}}> {i18n.language === 'en' ? item.full_name_en : item.full_name}</p>}
              actions={[
                <EditOutlined  
                  onClick={()=>handleOpenModal(item)}>แก้ไข</EditOutlined>,
                <FileDoneOutlined 
                  onClick={() => navigate(`${PATH.FARMER_PLANTATIONS}/${item.id}`)} />,
                <QrcodeOutlined 
                  onClick={() => setTargetQR(item)}/>,
                <DeleteOutlined onClick={() => setDeleteId(item.id)}/>
              ]}
            
            > 
              <Meta 
              description={
                <>
                <>เบอร์: {item.phone}</>
                <>{t("profiles.phone")} : {item.phone}</>
                <br/>
                <>{t("status")} : {item.status && t(`status_map.${item.status}`)}</>
                </>
              }/>
            </Card>
            </List.Item>))}
            </InfiniteScroll>
        </List>
        <br/>
        </div>
        ):(
        <TSTable 
          ref={tableRef}
          columns={columns}
          url={`${URL_MEMBER.FARMER}?filter_user_type=true`}
          rowKey={"id"}
          size={"small"}
          pageSize={10}/>
          
          )}
          <Modal
            visible={visible}
            onClose={handleCloseModal}
            closeOnAction
            title={t("want_to_edit")}
            content={
              <div style={{ textAlign: 'center' }}>
              <Space align='center'>
                <ColorButton 
                type={"primary"} 
                block size='large' 
                override={colorPrimary}
                onClick={()=>navigate(`${PATH.MEMBER_REGISTER}/${dataid.id}`)}
                >
                  {t("ok")}
                </ColorButton>
                <ColorButton 
                  block size='large' 
                  onClick={()=>handleCloseModal(false)}
                  >
                  {t("cancel")}
                </ColorButton>
              </Space>
              </div>
            }>
          </Modal>

          <Modal
            visible={deleteId ? true : false}
            onClose={handleCloseModal}
            closeOnAction
            title={t("do_you_want_to_delete_the_gardener")}
            content={
              <div style={{ textAlign: 'center' }}>
              <Space align='center'>
                <ColorButton 
                  type={"primary"} 
                  block size='large' 
                  override={colorError}
                  onClick={() => handleDelete(deleteId)}
                >
                  {t("delete")}
                </ColorButton>
                <ColorButton 
                  block size='large' 
                  onClick={() => handleCloseModal(false)} >
                  {t("cancel")}
                </ColorButton>
              </Space>
              </div>
            }>
          </Modal>

          <QRCodeModal
            open={targetQR ? true : false}
            memberId={targetQR ? targetQR.id : null}
            memberName={targetQR ? i18n.language === 'th' ? targetQR.full_name : targetQR.full_name_en : null}
            onClose={() => setTargetQR(null)} />
    </div>
    
  )
}
