import React from 'react';
import { Tabs } from 'antd';
import { useUserContext } from '../hooks/UserSettingProvider';
import { USER_TYPE } from '../constants/string';
import DashboardOfficerView from '../components/dashboard/DashboardOfficerView';
import { AimOutlined, GlobalOutlined , FileOutlined, FileSearchOutlined } from '@ant-design/icons';
import DashBoardCCView from '../components/dashboard/DashBoardCCView';
import DashBoardMapCC from '../components/dashboard/DashBoardMapCC';
import DashBoardMapOffice from '../components/dashboard/DashBoardMapOffice';
import DashBoardTransaction from '../components/dashboard/DashBoardTransaction';

export default function DashboardView() {
  const { user } = useUserContext();
  const userType = user.user_type;
  const [activeKey, setActiveKey] = React.useState("TAB1");
  const [activeKeyCC, setActiveKeyCC] = React.useState("1");

  const onTabsChange = (key) => setActiveKey(key);

  const items = [
      {
        key: "TAB1", 
        label: <span>Farmer and plantation</span>, 
        children: <DashboardOfficerView/>
      },
      {
        key: "TAB2", 
        label: <span>Map</span>,
        children: <DashBoardMapOffice/> 
      },
      {
        key: "TAB3", 
        label: <span>Transaction</span>, 
        children: <DashBoardTransaction/>
      }
  ]

  return (
    <>
      <Tabs
        activeKey={activeKey}
        onChange={onTabsChange}
        size='large'
        items={items} /> 
     
    </>

  )
}
