import React from "react";
import propTypes from "prop-types";
import { Col, Modal, Row, Input, message, Select, Spin, Popconfirm, theme, Alert } from "antd";
import Form from "antd/es/form/Form";
import { DELETE, GET, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_CUSTOMER, URL_COMMON } from "../../constants/urls";
import _ from "lodash";
import { LANGUAGE, VEHICLE_TYPE } from '../../constants/string';
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';
export default function CarRegisterModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [debouncedCustomer, setDebouncedCustomer] = React.useState(null);
  const [fetchingCustomer, setFetchingCustomer] = React.useState(false);
  const [customer, setCustomer] = React.useState([]);
  const [debouncedProvince, setDebouncedProvince] = React.useState(null);
  const [fetchingProvince, setFetchingProvince] = React.useState(false);
  const [province, setProvince] = React.useState([]);
  const { t, i18n } = useTranslation();

  const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();

  const formItemLayout = { labelCol: { span: 6 } }

  const debounceProvince = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedProvince(_searchVal) }, 800),
    []
  );

  const debounceCustomer = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCustomer(_searchVal) }, 800),
    []
  );

  const fetchProvince = async () => {
    setFetchingProvince(true);
    try {
      const response = await GET(`${URL_COMMON.PROVINCE}?search=${debouncedProvince}`)
      setProvince(response.data.map((item) => {
        return {
          ...item,
          label: i18n.language === LANGUAGE.TH ? item.name_th : item.name_en,
          value: item.id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetchingProvince(false);
    }
  }

  const fetchCustomer = async () => {
    setFetchingCustomer(true);
    try {
      const response = await GET(`${URL_CUSTOMER.CUSTOMER}?search=${debouncedCustomer}`)
      setCustomer(response.data.results.map((item) => {
        return {
          ...item,
          label: item.full_name,
          value: item.id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetchingCustomer(false);
    }
  }

  const handleOK = async () => {
    setLoading(true);
    let data = {}
    try {
      data = await form.validateFields();
      data['license_plate_province'] = _.get(data, 'license_plate_province.value', null)
      data['owner'] = _.get(data, 'owner.value', null)
      let response = {}
      if (props.target) {
        response = await PATCH(`${URL_CUSTOMER.VEHICLE}${props.target.id}/`, data)
      } else {
        response = await POST(URL_CUSTOMER.VEHICLE, data)
      }
      props.onUpdated(response.data);
      props.onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.VEHICLE}${id}/`)
      props.onUpdated();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const VEHICLE_TYPE_OPTIONS = [
    { key: VEHICLE_TYPE.TRUCK, value: VEHICLE_TYPE.TRUCK, label: t("truck")},
    { key: VEHICLE_TYPE.PICKUP, value: VEHICLE_TYPE.PICKUP, label: t("pickup") },
    { key: VEHICLE_TYPE.SIDECAR, value: VEHICLE_TYPE.SIDECAR, label: t("sidecar") },
    { key: VEHICLE_TYPE.MOTORCYCLE, value: VEHICLE_TYPE.MOTORCYCLE, label: t("motorcycle") },
    { key: VEHICLE_TYPE.OTHER, value: VEHICLE_TYPE.OTHER, label: t("other") },
  ]

  React.useEffect(() => {
    if (props.open) {
      const data = props.target
      if (data) {
        form.setFieldsValue({
          ...data, 
          license_plate_province: data.license_plate_province 
            ? { label: data.license_plate_province_name, value: data.license_plate_province }
            : null,
          owner: data.owner 
            ? { label: data.owner_full_name, value: data.owner }
            : null,
        })
      } else {
        form.resetFields();
      }
    }
  }, [props.open]);

  React.useEffect(() => {
    if (debouncedProvince)
      fetchProvince();
  }, [debouncedProvince])

  React.useEffect(() => {
    if (debouncedCustomer)
      fetchCustomer();
  }, [debouncedCustomer])


  return (
    <div>
      <Modal
        title={t("vehicle")}
        open={props.open}
        onCancel={props.onClose}
        confirmLoading={loading}
        width={800}
        onOk={handleOK}
        footer={
          <>
            {props.target &&
              <Popconfirm
                title={t("Confirm_vehicle_deletion")}
                onConfirm={() => handleDelete(props.target.id)}
                okText={t("delete")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}
                placement='topRight'>
          
                <ColorButton
                  style={{ float: 'left'}}
                  type={'primary'}
                  danger >
                  {t("actions.delete_data")}
                </ColorButton>
              </Popconfirm>
            }
            <ColorButton loading={loading} onClick={props.onClose}>{t("cancel")}</ColorButton>
            <ColorButton 
              style={{background: (props.target ? colorWarning : colorPrimary), color : 'white'}} 
              loading={loading} 
              onClick={handleOK}>
                {props.target ? t("edit")  : t("add_vehicle")}
            </ColorButton>
          </>}>

        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "8px", textAlign: "left"}}/>
        }

        <Form
          {...formItemLayout}
          form={form}
          autoComplete="off"
          labelCol={{ span: 8 }}>
          <Row gutter={16}>

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"type"}
                label= {t("vehicle_type"," :")}
                rules={[{ required: true }]}
              	valuePropName='value'
            	  initialValue={VEHICLE_TYPE.TRUCK}>

                <Select options={VEHICLE_TYPE_OPTIONS} />


              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"license_plate_number"}
                label={t("license_plate"," :")}
                rules={[{ required: true }]}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"license_plate_province"}
                label={t("province_registration"," :")}>
                <Select
                  initialvalues={"-"}
                  autoComplete="off"
                  showSearch
                  labelInValue
                  filterOption={false}
                  notFoundContent={fetchingProvince ? <Spin size="small" /> : "-"}
                  onSearch={debounceProvince}
                  options={province}
                  allowClear={"-"} />
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"owner"}
                label={ t("vehicle_owner"," :") }>
                <Select
                  initialvalues={"-"}
                  autoComplete="off"
                  showSearch
                  labelInValue
                  filterOption={false}
                  notFoundContent={fetchingCustomer ? <Spin size="small" /> : "-"}
                  onSearch={debounceCustomer}
                  options={customer}
                  allowClear={"-"} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

CarRegisterModal.defaultProps = {
  open: false,
  target: null,
  onClose: () => {},
  onUpdated: (data) => {}
}

CarRegisterModal.propTypes = {
  open: propTypes.bool,
  target: propTypes.object,
  onClose: propTypes.func,
  onUpdated: propTypes.func,
}