import React from 'react'
import propTypes from "prop-types";
import {  Alert, Button, Col, DatePicker, Divider, Form, Image, Input, Modal,  Row,  Select, theme, Spin, Checkbox } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import Header from '../common/Header';
import { GET, PATCH, POST } from '../../frameworks/HttpClient';
import { DATE_FORMAT, LANGUAGE } from '../../constants/string';
import Title from 'antd/es/typography/Title';
import userImg from '../../assets/user.png';
import ImgCrop from 'antd-img-crop';
import ImageEditorModal from "../common/ImageEditorModal";
import { URL_COMMON, URL_MEMBER } from '../../constants/urls';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import _ from "lodash";

export default function UserModal (props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [userGroupOptions, setUserGroupOptions] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [subdistricts, setSubdistricts] = React.useState([]);
  const [uploadFile, setUploadFile] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const { t, i18n } = useTranslation();
  const uploadRef = React.useRef(null);

  const debounceSubdistrict = React.useCallback(_.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800), []);

  const genderTH = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
    { value: 'เด็กชาย', label: t("prefix.boy") },
    { value: 'เด็กหญิง', label: t("prefix.girl") },
  ]

  const genderEN = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
  ]

	const {
		token: { colorPrimary, colorWarning, colorInfo },
	} = theme.useToken();

  const fetchSubdistrict = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
      setSubdistricts(response.data.results.map((item) => {
        return {
          ...item,
          label: i18n.language === LANGUAGE.TH ? item.display_name : item.display_name_en,
          value: item.subdistrict_id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const handleUserGroup = async () => {
    let data = null;
    try {
      data = await form.validateFields();
      if(imageData){
        data['card'] = imageData;
      }

      if (data.sub_district) 
      data['sub_district'] = data.sub_district.value

      if (data.birthdate)
      data["birthdate"] = data.birthdate.format(DATE_FORMAT);

      if(!props.target){
        await POST(URL_MEMBER.OFFICER, data);
      } 
      
      if(props.target){
        if (data.username)
          delete data["username"]
        
        await PATCH(`${URL_MEMBER.OFFICER}${props.target.id}/`,data)
      }
      
      props.onUpdated();

    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
    
  }

  React.useEffect(() => {
    if (debouncedCode)
      fetchSubdistrict();
  }, [debouncedCode])


	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			form.resetFields();
      const data = props.target
			if (props.target){
				form.setFieldsValue({
          ...data,
          birthdate: data.birthdate ? dayjs(data.birthdate) : null,
          sub_district: data.sub_district ? {label : i18n.language === LANGUAGE.TH ?  data.sub_district_detail : data.sub_district_detail_en, value : data.sub_district} : null
        })
			}
		} else {
      setImageData(null)
    }
	}, [props.open])
  

  return (
    <Modal
      width={"80%"}
			okText={props.target ? t("edit") : t("add")}
			okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
			open={props.open}
			onOk={handleUserGroup}
			onCancel={props.onClose}
      cancelText={t("cancel")}>
        <Header title={t("user.user")}></Header>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: 8, textAlign: "left"}}
          />
        }
				<Form 
					form={form}
					style={{marginTop: 16}}
					layout="vertical"
          autoComplete="off">

          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <Row gutter={16}>

                  <Col md={24} sm={24} xs={24}>
                    <div style={{textAlign: "center", marginTop: 16, height: 270}} className='section-wrapper'>
                      <Image
                        src={imageData ? imageData : (props.target && props.target.card) ? props.target.card : userImg}
                        height={200} 
                        width={"auto"}
                      />
                    <br/>
                      <Button 
                        style={{marginTop: 20, marginBottom : 10}}
                        icon={<UploadOutlined />} 
                        onClick={() => uploadRef.current.click()}>
                        {t("upload_image")}
                      </Button>
                    </div>   
                  </Col>
              </Row>
            </Col>
          
            <Col md={16} sm={24} xs={24}>
              <Row gutter={16}>
                <Col md={6} sm={24} xs={24}>
                  <Form.Item
                    name={"title"}
                    label={t("user.title")}
                    rules={[{ required: true }]}>
                    <Select
                      style={{ width: '100%' }}
                      options={i18n.language === "th" ? genderTH : genderEN}
                    />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name="first_name"
                    label={t("user.first_name")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name="last_name"
                    label={t("user.last_name")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"citizen_id"}
                    label={t("citizen_id")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"birthdate"}
                    label={t("user.birthdate")}
                    rules={[{ required: true }]}>
                      <DatePicker style={{ width: '100%', }} format={DATE_FORMAT}/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"gender"}
                  label={t("genders")}>
                    <Select style={{ width: '100%' }}
                      options={[
                        { value: 'm', label: t("gender.m") },
                        { value: 'f', label: t("gender.f") },
                        { value: 'unknown', label: t("gender.unknown") }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Form.Item 
                    name={"address"}
                    label={t("address")}
                    rules={[{ required: true }]}>
                    <Input.TextArea />
                  </Form.Item>
                </Col>

                <Col md={16} sm={24} xs={24}>
                  <Form.Item  
                    name={"sub_district"}
                    label={t("subdistrict_district_province_zip_code")}
                    rules={[{ required: true }]}>
                    <Select 
                      initialvalues={"-"}
                      autoComplete="off" 
                      showSearch
                      labelInValue
                      filterOption={false}
                      notFoundContent={fetching ? <Spin size="small" /> : "-"}
                      onSearch={debounceSubdistrict}
                      options={subdistricts}
                      allowClear={"-"}/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"nickname"}
                    label={t("user.nickname")}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={16}>
              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"phone"}
                  label={t("phone_number")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"email"}
                  label={t("email")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item 
                  name={"groups"}
                  label={t("user.system_access")}
                  rules={[{ required: true }]}>
                  <Select mode="multiple"                       
                    options={[
                      { value : 'officer_report', label: 'report' },
                      { value : 'officer_staff', label: 'staff' },
                      { value : 'officer_manager', label: 'manager' }
                    ]}/>
                </Form.Item>
              </Col>

                {(!props.target ) && <Col md={24} sm={24} xs={24}>
                  <Divider />
                  <Title level={5}>{t("system_usage_data")}</Title>
                  {!props.target && <p style={{color: "red"}}>{t("create_password_alert")}</p>}
                </Col>}
                
                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"username"}
                    label="Username"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" disabled={props.target ? true : false}/>
                  </Form.Item>
                </Col>
                
                {(!props.target ) && <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"password"}
                    label={t("password")}
                    rules={[{ required: true }]}>
                      <Input.Password autoComplete="off"/>
                  </Form.Item>
                </Col>}

                {(!props.target ) && <Col md={8} sm={24} xs={24}>
                  <Form.Item 
                    name={"confirm_passward"}
                    label={t("confirm_password")}
                    rules={[
                      { required: true, message: t('not_match_password') },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('not_match_password')));
                          },
                        }),
                    ]}>
                      <Input.Password autoComplete="off"/>
                  </Form.Item>
                </Col>}
            
          </Row>

        </Form>
      

      <ImgCrop aspect={16 / 9} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadFile(URL.createObjectURL(evt.target.files[0]))
              evt.target.value = null  // clear reference to enable select same file}
            }
        }}/>
      </ImgCrop>

      <ImageEditorModal
        open={uploadFile ? true : false}
        image={uploadFile}
        onUpdated={(image) => {
          setImageData(image)
          setUploadFile(null)
        }}
        onClose={() => setUploadFile(null)}/>

    </Modal>
  )
}
UserModal.defaultProps = {
  open: false,
	target: null,
  onUpdated: () => {},
	onClose: () => { },
}

UserModal.propTypes = {
  open: propTypes.bool,
	target: propTypes.object,
  onUpdated: propTypes.func,
	onClose: propTypes.func,
}