import { Col, Modal, Row, theme, message, Tooltip, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { GET } from "../../frameworks/HttpClient";
import { URL_COMMON, URL_DELIVERY, URL_PLANTATIONS, URL_REPORT } from "../../constants/urls";
import * as _ from "lodash";
import TSTable from "../common/TSTable";
import ErrorMessages from "../common/ErrorMessages";
import ColorButton from "../common/ColorButton";
import { PATH } from "../../routes/CustomRoutes";
import { EyeOutline } from "antd-mobile-icons";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, FileDoneOutlined, FilePdfOutlined, FileTextOutlined, FileExcelOutlined} from '@ant-design/icons';
import { LANGUAGE, PLANTATION_DOCUMENT_TYPE } from "../../constants/string";
import FactoryDelPlantationDetailModal from "./FactoryDelPlantationDetailModal";


export default function FactoryDeliveryModal (props) {
  const {
    open,
    target,
    onClose,
  } = props;

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [switchChecked, setSwitchCheck] = React.useState(false);
  const [openDetailTarget, setOpenDetailTarget] = React.useState(null)
  const [hasOverlapFilter, setHasOverLapFilter] = React.useState(false)
  const [detail, setDetail] = React.useState({ id: null, sender: '-', code: '-' })
  const tableRef = React.useRef();
  const [provinceOptions, setProvinceOptions] = React.useState([])
  const { token: { colorSuccess, colorInfo, colorWarning, colorPrimary } } = theme.useToken();

  const columns = [
    {
      title: t("plantations.plantation_code"),
      width: 120,
      dataIndex: "code",
      searcher: true,
      render: (value) => value ? value : '-',
    },
    {
      title: t("overall_area"),
      width: 120,
      dataIndex: "area_display",
    },
    {
      title:  t("overlap_area"),
      dataIndex: "overlap",
      width: 200,
      sorter: true,
      render: (value, original) => {
        if (original.overlap_layers_detail.total !== []) {
          return (
            <ul>
              { _.get(original, 'overlap_layers_detail', []).map(element => (
                <li>{t(`geography_layer_types.${_.get(element, 'type')}`)} : {_.get(element, 'name')}</li>
              )) }
            </ul>
          )
        }
        return <div>-</div>
      },
      // searcher: true,
    },
    {
      title: t("province"),
      width: 120,
      dataIndex: "overlap_province",
      searcher:  true,
      searcherOptions: provinceOptions,
      // sorter: true,
      render: (value, original) => {
        if (original.overlap_layers_detail.total !== []) {
          return (
            <ul>
              { _.get(original, 'overlap_layers_detail', []).map(element => (
                <li>{_.get(element, 'province_name')}</li>
              )) }
            </ul>
          )
        }
        return <div>-</div>
      },
    },
    {
      title: <div style={{ bacgroundColor : colorPrimary }}></div>,
      dataIndex: "id",
      width: 60,
      render: (value, original) => <div style={{ textAlign: 'center' }}>
        <Row>
          <Tooltip title="Upload Document">
          <ColorButton
            style={{ marginRight : 8}}
            type='primary'
            override={colorWarning}
            icon={<EditOutlined />}
            onClick={() => setOpenDetailTarget(original)}/>
          </Tooltip>
          <Tooltip title="Plantations Detail">
          <ColorButton
            type='primary'
            override={colorInfo}
            icon={<EyeOutline/>}
            onClick={() => window.open(`${PATH.PLANTATION_DETAIL}/${original.farmer}/${value}`, '_blank')} />
          </Tooltip>
        </Row>
      </div>
    }
  ]

  const fetchProvinces = async () => {
    try {
      const response = await GET(URL_COMMON.PROVINCE, {page_size: 999})
      setProvinceOptions(response.data.map((item) => {
        return {
          ...item,
          label: item.name_th,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}${target}/`)
      setDetail(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleDownloadReportExcel = async (id) => {
    try {
      setLoading(true)
      const response = await GET(`${URL_REPORT.REPORT_OF_PLANTATION}`, { del001 : id});
      window.open(response.requestUrl, '_blank');
    } catch (error) {
      message.error('Error loading excel report', error)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (target) {
      setSwitchCheck(false)
      fetchData()
      fetchProvinces();
    }
  }, [target])

  React.useEffect(() => {
    if (detail.id && tableRef.current) {
      tableRef.current.fetch();
    }
  }, [detail.id])

  React.useEffect(() => {
    if(tableRef.current){
      if(switchChecked){
        setHasOverLapFilter(true)
        tableRef.current.fetchWithoutResetPage({has_overlap : true })
      } else {
        setHasOverLapFilter(false)
        tableRef.current.fetchWithoutResetPage({has_overlap : false})
      }
    } 
  }, [switchChecked])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      closeIcon
      title={<>{t('plantations.detail') }</>}
      width={1000}
      footer={[]}>
      <ErrorMessages message={errorMessages}/>
        <ColorButton
            type='primary'
            override={colorPrimary}
            loading={loading}
            style={{ marginBottom : 10, marginTop : 10}}
            icon={<FileExcelOutlined />}
            onClick={() => handleDownloadReportExcel(target)}
          >
            {t("reports.report_of_plantation")}
        </ColorButton>  
      <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col md={7} sm={24}>
          <span style={{ fontWeight: 'bold' }}>{t("factory_del.del_number")} :</span> {detail.code}
        </Col>
        <Col md={9} sm={24}>
          <span style={{ fontWeight: 'bold' }}>{t("factory_del.collecting_center_name")} :</span> {i18n.language === LANGUAGE.TH ? detail.sender_name : detail.sender_name_en}
        </Col>
        <Col md={8} sm={24}>
          <span style={{ fontWeight: 'bold' }}>{t("factory_del.product")} :</span> {detail.product_name}
        </Col>
      </Row>
      <div style={{ marginBottom : 20, flex:'right'}}><Switch defaultChecked={false} onClick={setSwitchCheck} checked={switchChecked} /> {t('overlaps_filter')}</div>
      <TSTable
        ref={tableRef}
        columns={columns}
        url={URL_PLANTATIONS.PLANTATIONS}
        params={{ delivery_transaction: detail.id, ordering : '-overlap', has_overlap : hasOverlapFilter }}
        autoFetch={false}
        loading={loading}
      />

      <FactoryDelPlantationDetailModal
        open={openDetailTarget ? true : false}
        target={openDetailTarget}
        onClose={() => setOpenDetailTarget(null)}
        polygonLayer={[]}
      />

    </Modal>
  )
}