import React from 'react';
import propTypes from "prop-types";
import {Button, Upload, message} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ColorButton from './ColorButton';

const UploadFile = React.forwardRef((props, ref) => {
  const { readOnly } = props;
  const [uploading, setUploading] = React.useState([]);
  const [state , setState] = React.useState({
    selectedFile: null,
    selectedFileList: []
  });

  React.useImperativeHandle(ref, () => ({
    getData: () => {
      return formatData();
    },
    clearFile : () => {
      setState({selectedFileList : []});
    }
  }));

  const formatData = () => {
    try {
      if(state.selectedFileList)
        return state.selectedFileList.filter((item) => uploading.includes(item.uid))
    } catch (error) {
        message.error(error.errorMessage);
    }

    return []   
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = info.fileList;
        break;
      case "done":
        nextState.selectedFile = info.file;
        setUploading([...uploading,info.file.uid]);
        nextState.selectedFileList = info.fileList;
        props.onUploaded();
        break;
      case "removed":
        nextState.selectedFile = null;
        nextState.selectedFileList = info.fileList;
        break;

      default:
        // error 
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    setState(() => nextState);
  };

  React.useEffect(() => {
    if (props.dataList) {
      let file = []
      props.dataList.map((item ,index) => {
        if(item.url){
          const filename = item.url.replace(/^.*[\\/]/, '')
          file.push({...item , name: item.name ? item.name: filename })
        }
      })
      setState({ selectedFileList : file });
    }
  }, [props.dataList])

    return (
      <div>
        <Upload
          className={props.className}
          multiple
          fileList={state.selectedFileList}
          customRequest={dummyRequest}
          onChange={onChange}
          listType="picture"
          maxCount={props.maxCount}
          onRemove={(data) => uploading.includes(data.uid) ? null : props.onRemove(data)}>
          <Button
            style={{...props.style}}
            icon={props.icon}
            type='primary'
            disabled={props.readOnly}
          >
            {props.btnText}
          </Button>
        </Upload>
      </div>
  );
});

UploadFile.defaultProps = {
	style: {},
	btnText: "Choose File",
  readOnly: false,
	icon: <UploadOutlined />,
	maxCount: 20,
  dataList: [],
  onRemove: () => {},
  className: "",
	onClick: () => {},
  onUploaded: () => {}
}

UploadFile.propTypes = {
	style: propTypes.object,
  readOnly: propTypes.bool,
	btnText: propTypes.string,
	icon: propTypes.element,
	maxCount: propTypes.number,
  dataList: propTypes.array,
	onRemove: propTypes.func,
	className: propTypes.string,
}

export default React.memo(UploadFile);
