import React, { useRef } from "react";
import { Col, Modal, Row, theme, message, Spin, List } from "antd";
import { useTranslation } from "react-i18next";
import * as _ from 'lodash';
import UploadFile from "../common/UploadFile";
import { URL_PLANTATIONS, URL_GEOGRAPHY } from "../../constants/urls";
import { DELETE, GET, POST } from "../../frameworks/HttpClient";
import ErrorMessages from "../common/ErrorMessages";
import { PLANTATION_DOCUMENT_TYPE } from "../../constants/string";

export default function FactoryDelPlantationDetailModal (props) {
  const [errorMessages , setErrorMessages] = React.useState(null);
  const { t } = useTranslation();
  const deedRef = useRef();
  const documentRef = useRef();
  const { token: { colorSuccess, colorInfo, colorWarning, colorPrimary } } = theme.useToken();
  const [ deedDocument, setDeedDocument] = React.useState([])
  const [ additionalDocument, setAdditionalDocument] = React.useState([])
  

  const {
    open,
    target,
    onClose,
  } = props;

  const handleUploadFile = async() => {
    try {
      if (documentRef.current) {
        const documentDeed = await deedRef.current.getData()
        await uploadFile(documentDeed, PLANTATION_DOCUMENT_TYPE.DEED, target.id)
        if(documentDeed.length > 0)
          message.success(t('upload_document'))
      }
      if(deedRef.current){
        const documentAdditional = await documentRef.current.getData()
        await uploadFile(documentAdditional, PLANTATION_DOCUMENT_TYPE.ADDITIONAL, target.id)
        if(documentAdditional.length > 0)
          message.success(t('upload_document'))
      }
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages) 
    }
  }

  const uploadFile = async(document, type, id) => {
    if(document != null) {
      await Promise.all(
        document.map(async (item) => {
          let file = {plantation: id, type: type, document: item.originFileObj}
          try {
            await POST(URL_PLANTATIONS.DOCUMENT, file, true)
          } catch (error) {
            console.log(error.errorMessages);
          }
        })
      )
    }
  }

  const fetchDocuments = async (plantId) => {
    setErrorMessages(null);
    try {
      let documentData = [];
      const document = await GET(`${URL_PLANTATIONS.DOCUMENT}?plantation=${plantId}`)
      document.data.results.map(data => documentData.push({...data, url: data.document}));
      console.log('Work')
      console.log(documentData)
      setDeedDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.DEED))
      setAdditionalDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.ADDITIONAL))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if(target){
      fetchDocuments(target.id)
    }
  },[target])

  return(
    <Modal
      open={open}
      onCancel={onClose}
      closeIcon
      title={t('plantations_detail_del')}
      width={1200}
      onOk={() => handleUploadFile()}>
      <ErrorMessages message={errorMessages}/>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col md={6} sm={24}>
          <span style={{ fontWeight: 'bold' }}>{t('plantations.plantation_code')} :</span> {_.get(target, 'code', '')}
        </Col>
        <Col md={18} sm={24}>
          <span style={{ fontWeight: 'bold' }}>{t('overall_area')} :</span> {_.get(target, 'overall_area', '')}
        </Col>

        <Col md={12} sm={24} xs={24}>
          <span>{t("proof_of_rights_documents")}</span>
          <UploadFile
            className={"upload-list-inline"}
            style={{ background : colorPrimary , color : 'white'}}
            btnText = {t("upload_proof_of_rights_documents")} 
            maxCount = {3}
            ref = {deedRef}
            dataList={deedDocument ? deedDocument : null}
            onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
          />
          <p style={{ color: "red" }}>
            {t("please_upload_file")}
          </p>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <span>{t("additional_document")}</span>
          <UploadFile
            className={"upload-list-inline"}
            style={{ background : colorPrimary , color : 'white'}}
            btnText = {t("upload_proof_of_rights_documents")} 
            maxCount = {3}
            ref = {documentRef}
            dataList={additionalDocument ? additionalDocument : null}
            onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
          />
          <p style={{ color: "red" }}>
            {t("please_upload_file")}
          </p>
        </Col>
      </Row>
    </Modal>
  )}