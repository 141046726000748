import React from "react";
import { GET } from "../../frameworks/HttpClient";
import { useUserContext } from "../../hooks/UserSettingProvider";
import { ShopFilled, MailFilled, PhoneFilled, HomeFilled } from '@ant-design/icons';
import { URL_MEMBER } from "../../constants/urls";
import DetailItem from "../common/DetailItem"
import { Card, Button, theme, message, Row } from "antd";
import EditAdminCCDetailModal from "./EditAdminCCDetailModal";
import ColorButton from "../common/ColorButton";
import { useWindowDimensionContext } from "../../hooks/WindowDimensionProvider";
import LogoutModal from "../user/LogoutModal";
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from "../../constants/string";



export default function AdminCCDetailView() {
  const { user } = useUserContext();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const [data, setData] = React.useState(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { t, i18n } = useTranslation();

  const { token: { colorWarning } } = theme.useToken();

  const fetchData = async () => {
    try {
      const response = await GET(`${URL_MEMBER.USER_COLLECTING_CENTER}`)
      setData(response.data)
    } catch (error) {
      message.error(error.errorMessage);
    }
  }

  React.useEffect(() => {
    if (user)
      fetchData();
  }, [])

  return (
    <div>
      <Card>
        <DetailItem
          style={{ padding: 10 }}
          title={t("profiles.first_name")}
          titleIcon={<ShopFilled />}
          detail={data ? data.name : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("profiles.email")}
          titleIcon={<MailFilled />}
          detail={data ? data.email : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("collecting_centers.phone")}
          titleIcon={<PhoneFilled />}
          detail={data ? data.phone : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("address")}
          titleIcon={<HomeFilled />}
          detail={data ? data.address : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("subdistrict_district_province_zip_code")}
          titleIcon={<HomeFilled />}
          detail={data ? i18n.language === LANGUAGE.TH ? data.sub_district_detail : data.sub_district_detail_en : ''}
        />
        <div style={{ textAlign: 'center' }}>
          <ColorButton
            size='large'
            type='primary'
            override={colorWarning}
            style={{ marginTop: 16, marginRight: 10}}
            onClick={() => setOpenEditModal(true)}
          >
            {t("edit")}
          </ColorButton>
        </div>
        
      </Card>

      {isOnMobile && (
        <div style={{ textAlign: 'center' }}>
          <ColorButton
            danger
            size='large'
            type='primary'
            override={colorWarning}
            style={{ marginTop: 16, marginRight: 10}}
            onClick={() => setOpenLogoutModal(true)}
          >
            {t("profiles.sign_out")}
          </ColorButton>
        </div>
        )}

      <EditAdminCCDetailModal
        open={openEditModal}
        data={data}
        onUpdate={() => {
          setOpenEditModal(false);
          fetchData();
        }}
        onClose={() => {
          setOpenEditModal(false);
        }}
      />

      <LogoutModal
      open={openLogoutModal}
      onClose={() => {
        setOpenLogoutModal(false);
      }}/>

    </div>
  )
}