import React from 'react';
import { Col, Row, Select, Space, Tooltip, theme } from 'antd';
import { SwapOutlined, FileDoneOutlined, AuditOutlined, FileOutlined, FilePdfOutlined, FileExcelOutlined} from '@ant-design/icons';
import { URL_PLANTATIONS, URL_REPORT } from '../constants/urls';
import TSTable from '../components/common/TSTable';
import { PATH } from '../routes/CustomRoutes';
import ChangeStateModal from '../components/officer/ChangeStateModal';
import ColorButton from '../components/common/ColorButton';
import CheckPlantationModal from '../components/officer/CheckPlantationModal';
import { DATETIME_FORMAT, DATE_FORMAT, HUMAN_DATETIME_FORMAT, HUMAN_DATE_FORMAT, PLANTATION_STATUS, USER_TYPE } from '../constants/string';
import moment from 'moment';
import ReportButton from '../components/common/ReportButton';
import ConfirmPlantitonModal from '../components/collectingcenter/ConfirmPlantitonModal';
import { useTranslation } from 'react-i18next';
import useDropdownItem from '../hooks/useDropdownItem';
import dayjs from 'dayjs';

export default function PlantationsVerifyView() {
  const titleDeedDropdown = useDropdownItem({ url: URL_PLANTATIONS.DEED_TYPE, label: 'name', value: 'name', pagination: true, params: { page_size: 999 } })
  const [openChangeStatModal, setOpenChangeStateModal] = React.useState(false)
  const [openCheckModal, setOpenCheckModal] = React.useState(false)
  const [openConfirmPlantion, setOpenConfirmPlantiton] = React.useState(false)
  const [filterStatus, setFilterStatus] = React.useState("status=waiting&status=checking")
  const [target, setTarget] = React.useState(null);
  const tableRef = React.useRef();
  const [loadings, setLoadings] = React.useState([]);
  const { t } = useTranslation();

  const {
		token: { colorWarning, colorInfo },
	} = theme.useToken();

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const FARMER_STATUS = {
    draft: t("farmer_status.draft"), 
    checking: t("farmer_status.checking"), 
    waiting: t("farmer_status.waiting"), 
    approved: t("farmer_status.approved"), 
    rejected: t("farmer_status.rejected"), 
  }

  const buttonloading = (original,index) =>{
    try{
      setLoadings((state) => {
        const newLoadings = [...state];
        newLoadings[index] = true;
        return newLoadings;
      });
      if(original){
        window.open(original.recent_survey_documents[0])
      }
    }
    catch{
    }
    finally{
        setLoadings((state) => {
          const newLoadings = [...state];
          newLoadings[index] = false;
          return newLoadings;
      });
    }
    }

    const exportTable = async(type) => {
      if(type == "pdf"){
        await tableRef.current.exportPDF({
        title: t("plantations.pending_review"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        landscape: true
      })
      } else {
        await tableRef.current.exportXlsx({
        title: t("plantations.pending_review"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `งานรอตรวจสอบแปลงสวนยาง - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })}
    } 

  const columns = [
    {
      title: t("plantations.approval_date"),
      dataIndex:"created",
      render: (value) => moment(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT),
      sorter: true,
    },
    {
      title: t("plantations.lastest_update"),
      dataIndex:"updated",
      render: (value) => moment(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT),
      sorter: true,
    },
    {
      title: t("plantations.member_name"),
      dataIndex: "farmer_name",
      sorter: true,
      searcher: true,
    },
    {
      title: t("plantations.plantation_code"),
      width: 120,
      dataIndex: "code",
      searcher: true,
    },
    {
      title: t("plantations.deed_type"),
      width: 160,
      dataIndex: 'title_deed_type_name',
      searcher: true,
      searcherOptions: [...titleDeedDropdown.options],
    },
    {
      title: t("plantations.deed_code"),
      width: 160,
      dataIndex: 'title_deed_no',
    },
    {
      title: t("plantations.status"),
      width: 150,
      dataIndex: 'status',
      render: (value, original) => {
        return FARMER_STATUS[value]
      },
    },
    {
      title: t("plantations.under_collecting_center"),
      dataIndex:'farmer_created_by',
      searcher: true,
      render:(value , original) =>{
        return (original.farmer_created_by_type && original.farmer_created_by_type === USER_TYPE.OFFICER || original.farmer_created_by_type === USER_TYPE.COLLECTING_CENTER ? `${original.farmer_created_by}` : '-')
      },
      renderReport:(value , original) => {
        return value
      }
    },
    {
      title: t("plantations.document"),
      width: 140,
      dataIndex: '',
      skipReport: true,
      render: (value, original,index) => {
        return(
          <div style={{ textAlign : 'center'}}>
          {original.recent_survey_documents[0] && 
            <Tooltip title={t("download")}>
              <ColorButton 
                icon={<FileOutlined />}
                onClick={() => {              
                  // window.open(original.recent_survey_documents[0])
                  buttonloading(original,index)
                }}
              />
              
            </Tooltip>
            
          }
          </div>
        )
      }
    },
    {
      title: 'Action',
      width: 140,
      dataIndex: 'id',
      skipReport: true,
      render: (value, original) => {
        // const isFSC = (original.traceability === PLANTATION_TRACEABILITY.FSC)
        return (
          <div style={{textAlign: "center"}}>
            <Space>
              {/* {original.farmer_is_fsc === true && 
              <Tooltip title={ isFSC? "เปลี่ยนประเภทแปลงเป็น CT" : "เปลี่ยนประเภทแปลงเป็น FSC"}>
              <ColorButton
              type='primary' 
              override={isFSC ? '#EC1D00': colorPrimary}
              icon={isFSC ? <DownCircleOutlined /> : <UpCircleOutlined />}
              onClick={() => {
                setOpenConfirmPlantiton(true) 
                setTarget(original)
              }}
              >
              </ColorButton>
              </Tooltip>
              } */}
            {original.status === 'waiting' && <Tooltip title={t("plantations.update_status")}>
              <ColorButton 
                type='primary' 
                override={colorWarning}
                icon={<SwapOutlined />}
                onClick={() => {
                  setOpenChangeStateModal(true) 
                  setTarget(original)
                }} />
            </Tooltip>}
            {original.status === 'checking' && <Tooltip title={t("plantations.update_status")}>
              <ColorButton
                type='primary' 
                override={'#FF7800'}
                icon={<AuditOutlined />}
                onClick={() => {
                  setOpenCheckModal(true)
                  setTarget(original)
                }} />
            </Tooltip>}
            <Tooltip title={t("plantations.detail")}>
              <ColorButton 
                // style={{marginLeft: 4, marginRight: 4, background: colorInfo, color: 'white'}} 
                type='primary' 
                override={colorInfo}
                icon={<FileDoneOutlined />}
                onClick={() => window.open(`${PATH.PLANTATION_DETAIL}/${original.farmer}/${value}`)} />
            </Tooltip>
            <Tooltip title={t("plantations.download")}>
                <ReportButton
                  icon={<FilePdfOutlined />}
                  url={`${URL_REPORT.APP05}?plantation=${original.id}`}
                >
                </ReportButton>
            </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ];

  React.useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch()
    }
  }, [filterStatus]);

  return (
    <div>
      <Row gutter={[16, 16]} style={{marginBottom: 16}}>
        <Col md={6} sm={24} xs={24}>
          <Select
            style={{width: "100%"}}
            value={filterStatus}
            onChange={value => setFilterStatus(value)}
            options={[
              { value: 'status=waiting&status=checking', label: t("all") },
              { value: 'status=checking', label: t("plantations_status.checking") },
              { value: 'status=waiting', label: t("plantations_status.waiting") },
            ]}/>
        </Col>
          <Col md={18} sm={24} xs={24}>
            <ColorButton
              icon={<FileExcelOutlined />}
              style={{float: "right",  marginLeft : 8 }}
              onClick={() => exportTable("excel")}>
              Excel
            </ColorButton>
            <ColorButton
              icon={<FilePdfOutlined />}
              style={{float: "right",}}
              onClick={() => exportTable("pdf")}>
              PDF
            </ColorButton>
          </Col>
      </Row>

      <TSTable
        ref={tableRef}
        autoFetch={false}
        columns={columns}
        url={`${URL_PLANTATIONS.PLANTATIONS}?${filterStatus}`}
        rowKey={"id"}
        params={{ filter_user_type: true }}
        rowClassName={(record) => {
          if (record.is_critical && record.status === PLANTATION_STATUS.CHECKING) {
            return 'table-row-red'
          }
        }}
        size={"small"}
        pageSize={10}/>

      <ChangeStateModal
        open={openChangeStatModal}
        onUpdate={() => {
          setOpenChangeStateModal(false)
          setTarget(null)
          refreshData();
        }}
        onClose={() => {
          setOpenChangeStateModal(false)
          setTarget(null)
        }}
        data = {target}
      />

      <CheckPlantationModal
        open={openCheckModal}
        onUpdate={() => {
          setOpenCheckModal(false)
          setTarget(null)
          refreshData();
        }}
        onClose={() => {
          setOpenCheckModal(false)
          setTarget(null)
        }}
        data = {target}
      />
      <ConfirmPlantitonModal
        open={openConfirmPlantion}
        onClose={() => {
          setOpenConfirmPlantiton(false)}}
        onUpdate={() => {
          setOpenConfirmPlantiton(false)
            setTarget(null)
            refreshData();
          }}
          data = {target}
      />
    </div>
  )
}