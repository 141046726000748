import React from 'react';
import { AutoComplete, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Layout, Row, Spin, message, theme } from 'antd';
import { Content } from 'antd/es/layout/layout'
import HeaderScreen from '../components/common/HeaderScreen';
import { BG_LAYOUT_COLOR, PRIMARY_COLOR } from '../constants/color';
import { useParams } from 'react-router-dom';
import { GET, PATCH, PUT } from '../frameworks/HttpClient';
import { URL_DELIVERY } from '../constants/urls';
import ErrorMessages from '../components/common/ErrorMessages';
import { SaveOutlined } from '@ant-design/icons';
import { DATE_FORMAT, HUMAN_DATE_FORMAT, LANGUAGE, UNIT} from '../constants/string';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import Header from '../components/common/Header';
import ColorButton from '../components/common/ColorButton';
import UploadFile from '../components/common/UploadFile';
import CustomUpload from '../components/common/CustomUpload';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../hooks/UserSettingProvider';



export default function DeliveryOrderScreen() {
  const { user } = useUserContext();
  const params = useParams();
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { token: { colorSuccess } } = theme.useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const [delData, setDelData] = React.useState(null);
  const [fileData, setFileData] = React.useState(null);
  const uploadRef = React.useRef(null);
  const { t, i18n } = useTranslation();


 const UNIT_TRANSLATION = {
    [UNIT.GRAM]: t("gram"),
    [UNIT.KILOGRAM]: t("kilogram"),
    [UNIT.TONNE]: t("tonne"),
    [UNIT.LITRE]: t("litre"),
  }
  
  const {
    token: { colorPrimary, colorBgContainer },
  } = theme.useToken();

  const filterEmptyOut = (jsonInput) => {
    const entries = Object.entries(jsonInput).map(([key, value]) => [key, value ? value : ''])
    return Object.fromEntries(entries)
  }

  // Submit form
  const handleDelivery = async () => {
    setErrorMessages(null);
    setLoading(true);
    try {
      let data = await form.validateFields();
      let file = null
      if(uploadRef.current)
        file = await uploadRef.current.getData();
        if(file.length > 0){
          data['attach_file'] = file[0].originFileObj
        } else {
          delete data['attach_file']
        }

      if (data['receive_date'])
        data['receive_date'] = data.receive_date.format(DATE_FORMAT)

      if (data['sent_date'])
        data['sent_date'] = data.sent_date.format(DATE_FORMAT)
      data = filterEmptyOut(data)
      await PATCH(`${URL_DELIVERY.DELIVERY_TRANSACTION}${params.id}/`, data, true)
      messageApi.success(t("successfully_saved"))
      fetchData(params.id);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchData = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/`)
      let data = response.data
      setDelData(response.data)
      form.setFieldsValue({
        ...response.data,
        receiver_name : i18n.language === LANGUAGE.EN ? _.get(data, 'receiver_name_en', '-') :  _.get(data, 'receiver_name', '-'),
        sender_name : i18n.language === LANGUAGE.EN ? _.get(data, 'sender_name_en', '-') :  _.get(data, 'sender_name', '-'),
        receive_date: _.get(response, 'data.receive_date') ? dayjs(_.get(response, 'data.receive_date'), DATE_FORMAT) : null,
        sent_date: _.get(response, 'data.sent_date') ? dayjs(_.get(response, 'data.sent_date'), DATE_FORMAT) : null,
      })

      data = ({url : _.get(response,'data.attach_file') ? _.get(response,'data.attach_file') : null});
      setFileData(data);
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (params.id) {
      fetchData(params.id)
    }
  }, [params])

  React.useEffect(() => {
    console.log(user)
  },[user])

  React.useEffect(() => {
    if(i18n.language === LANGUAGE.EN){
      form.setFieldValue('receiver_name', _.get(delData, 'receiver_name_en', '-'))
      form.setFieldValue('sender_name', _.get(delData, 'sender_name_en', '-'))
    } else {
      form.setFieldValue('receiver_name', _.get(delData, 'receiver_name', '-'))
      form.setFieldValue('sender_name', _.get(delData, 'sender_name', '-'))
    }
  },[i18n.language])

  return (
    <Layout style={{ minHeight: '100vh', background: BG_LAYOUT_COLOR }}>
      {contextHolder}
      <HeaderScreen title={t("rubber_delivery_note_information")}/>
      <Content
        style={{ 
          margin: "0px 24px 24px 24px", 
          padding: 24,
          textAlign: "left", 
          minHeight: 280,
          background: colorBgContainer}}>
        <ErrorMessages message={errorMessages}/>
        <Spin spinning={loading}>
          <Form 
            form={form}
            style={{marginTop: 16}}
            layout="vertical"
            autoComplete="off">
            <Row gutter={16}>
              <Col md={16} sm={24} xs={24}>
                <Row>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item name='product_name' label={t("menu.product")}>
                      <Input bordered={false}/>
                    </Form.Item>
                  </Col>
                  
                </Row>

                <Row>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item name='sender_name' label={t("menu.collecting_center")}>
                      <Input bordered={false}/>
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={24} xs={24}>
                    <Form.Item name='receiver_name' label={t("dashboard.factory")}>
                      <Input bordered={false}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item
                  name={"attach_file"}
                  label={t("attach_upload_document")}>
                  <UploadFile
                    className={"upload-list-inline"}
                    btnText = {t("upload_document")} 
                    maxCount = {1}
                    ref = {uploadRef}
                    readOnly={(!user.factory_manage_tz)}
                    dataList={fileData ? [fileData] : null}
                    onRemove={() => PATCH(`${URL_DELIVERY.DELIVERY_TRANSACTION}${params.id}/`, { attach_file : null } )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='vehicle_license_plate' label={t("license_plate")}>
                  <AutoComplete>
                    <Input/>
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='driver_name' label={t("driver")}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='driver_phone' label={t("profiles.phone")}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={t("photo_stamp")}>
                  <CustomUpload
                    single
                    url={URL_DELIVERY.DRIVER_ATTACHMENT}
                    target={_.get(params, 'id', '')}
                    color={PRIMARY_COLOR}
                    text={t("upload")}
                    value={form.getFieldValue('driver_attachment')}
                    field='driver_attachment'
                    readOnly={(!user.factory_manage_tz)}
                    manual={false}
                    onSuccess={() => fetchData(params.id)}
                    onRemove={() => fetchData(params.id)}
                    description={t("attach_vehicle_registration_photo")}/>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='sent_date' label={t("export_date")}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format={HUMAN_DATE_FORMAT}/>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='receive_date' label={t("vehicle_arrival_date")}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format={HUMAN_DATE_FORMAT}/>
                </Form.Item>
              </Col>
            </Row>

            <Header title={t("factory_information")}></Header>
            <Row gutter={16} style={{marginTop: 16}}>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='sent_weight' label={t("factory_del.weight_from_collecting_center")}>
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={UNIT_TRANSLATION[UNIT.KILOGRAM]}/>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='in_weight' label={t("weight_weighed_in")}>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={UNIT_TRANSLATION[UNIT.KILOGRAM]}/>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='out_weight' label={t("weight_weighed_out")}>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={UNIT_TRANSLATION[UNIT.KILOGRAM]}/>
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item name='ri_no' label={t("ri_number")}>
                  <Input/>
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item name='stocks_code' label={t("stocks")} >
                  <Input readOnly disabled={true}/>
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item name='acfl_no' label={t("acfl_number")}>
                  <Input/>
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item name='drc' label='%DRC'>
                  <InputNumber
                    style={{ width: '100%' }}
                    max={100}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter='%'/>
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='vfa' label='น้ำหนักเนื้อยางแห้ง Net WT.'>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='nh3_amount' label={t("amount_of_ammonia_used")}>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item name='net_receive' label={t("net_receive")} style={{display:'none'}}>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={t("baht")}
                  />
                </Form.Item>
              </Col>
              <Col md={16} sm={24} xs={24}>
                <Form.Item name='net_receive_ref' label={t("net_receive_ref")} style={{ display: 'none' }}>
                  <Input/>
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item name='paid_amount' label={t("paid_amount")} style={{ display: 'none' }}>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={t("baht")}/>
                </Form.Item>
              </Col>
              <Col md={16} sm={24} xs={24}>
                <Form.Item name='paid_amount_ref' label={t("paid_amount_ref")} style={{ display: 'none' }}>
                  <Input/>
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item name='raot_price_per_kg' label={t("central_price_rubber_authority_of_thailand")} style={{ display: 'none' }}>

                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={t("baht_per_kilogram")}/>
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item name='agriac_price_per_kg' label={t("agriac_average_price")} style={{ display: 'none' }}>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonAfter={t("baht_per_kilogram")}/>
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item name='note' label={t("scale.note")}>
                  <Input.TextArea
                    style={{width: '100%'}}
                    autoSize={{minRows: 3, maxRows: 5}}/>
                </Form.Item>
              </Col>

              <Col md={16} sm={24} xs={24}>
                <Form.Item 
                  style={{ display: 'none' }}
                  name={"is_agriac_supply_chain"} 
                  valuePropName="checked">
                  <Checkbox>{t("rubber_consignment_note_delivery_agriac")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              {user.factory_manage_tz && <ColorButton
                size='large'
                type='primary'
                icon={<SaveOutlined/>}
                override={colorSuccess}
                onClick={handleDelivery}>
                {t("save")}
              </ColorButton>}
            </div>
          </Form>
        </Spin>
      </Content>
    </Layout>
  )
}